import { config } from './Config'

// TODO: Temporarily comment all Mixpanel events on this page as requested by Rush on 16/08/2022 suggesting
// the project will have it's own custom set-up
// mixpanel.init(config.mixpanelToken, { debug: config.debugMode })

const oktaSignIn = new OktaSignIn({
    baseUrl: 'https://medaire-client-portal-sandbox.oktapreview.com',
    clientId: config.OktaClientID,
    issuer: config.OktaIssuer,
    redirectUri: window.location.origin + '/login/callback',
    pkce: true,
    authParams: {
        issuer: config.OktaIssuer,
        scopes: ['openid', 'profile', 'email', 'offline_access']
    },
    colors: {
        brand: '#5FE9D0' // If you want even more customization, you can modify the Sass source files https://github.com/okta/okta-signin-widget#colors
    },
    features: {
        showPasswordToggleOnSignInPage: true
    },
    i18n: {
        'en': {
            'primaryauth.title': 'Please sign in to continue',
            'primaryauth.username.placeholder': 'Email',
            'error.username.required': 'Please enter email',
            'errors.E0000004': 'Invalid email or password'
        }
    }
})

oktaSignIn.authClient.token.getUserInfo().then(function(user) {
   console.info('User ' + user.email + ' is already logged in and attempted to see this login page, we should just redirect him back to the app.')
   window.location.href = '/app/index.html'
}, function() {
    document.addEventListener('DOMContentLoaded', () => {
        oktaSignIn.showSignInToGetTokens({
           el: '#okta-login-container'
        }).then(function(tokens) {
            if ((tokens.accessToken.claims.mam !== undefined && tokens.accessToken.claims.mam[0] === 'MedAire Account Managers') || 
                 tokens.accessToken.claims.is_administrator) {
                oktaSignIn.authClient.tokenManager.setTokens(tokens)

                // mixpanel.identify(tokens.idToken.claims.sub)
                // mixpanel.people.set({ 'email': tokens.idToken.claims.email })
                // mixpanel.people.set({ 'Client Domain': tokens.idToken.claims.email.split('@')[1] })
                // mixpanel.people.set({ 'name': tokens.idToken.claims.name })
                // mixpanel.people.set({ 'domain': window.location.hostname })
                
                // User could be resetting the password or some other future alternative way
                // const userNameInputElement = document.getElementById('okta-signin-username')
                // if (userNameInputElement !== null) {
                //     mixpanel.track('Sign In', {
                //         'email': document.getElementById('okta-signin-username').value,
                //         'domain': window.location.hostname
                //     })
                // }
                        
                if (tokens.accessToken !== null) {
                    window.location.href = '/app/index.html'
                } else {
                    window.location.reload()
                }
            } else {
                alert('Access not allowed.')
                window.location.reload()
            }
        }).catch(function(err) {
            window.alert(err)
        })
    })
})