// Default values at the top overriden for specific envirnoments
let websiteHost = 'https://localhost:7087'
let mixpanelToken = '4fb38938ca8bc0cd279b817667dcf3fd'
let debugMode = true

if (TARGET_ENV === 'test') {
    websiteHost = ''
} 

if (TARGET_ENV === 'staging') {
    websiteHost = ''
}

if (TARGET_ENV === 'uat') {
    websiteHost = ''
}

if (TARGET_ENV === 'production') { 
    websiteHost = ''
    mixpanelToken = '532aa85b37a2d5b3eedbc1a2d69d343d'
    debugMode = false
}

export const config = {
    OktaIssuer: 'https://medaire-client-portal-sandbox.oktapreview.com/oauth2/default',
    OktaClientID: '0oa4dumfg3z0xbWR90x7',
    debugMode: debugMode,
    mixpanelToken: mixpanelToken,
    APIEndPoint: websiteHost + '/api'
}